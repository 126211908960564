<template>
  <validation-observer v-slot="{ invalid }">
    <pay-less-card
      :offerAmountLabel="ic.offerAmountLabel"
      :offerDateLabel="ic.offerDateLabel"
      :porsodStrategies="$store.state.porsodStrategies"
      :portngStrategies="$store.state.portngStrategies"
      :negotiatorSOD1="ic.negotiatorSOD1"
      :negotiatorSOD2="ic.negotiatorSOD2"
      @pay-less-card-updated="updatePayLessCard"
    />
    <div class="field">
      <p class="content"><em>* indicates required field</em></p>
    </div>
    <div class="field">
      <div class="buttons">
        <button :disabled="invalid" class="button is-success" @click="save">
          Save
        </button>
        <button :disabled="invalid" class="button is-info" @click="saveNext">
          Save and Next
        </button>
        <button class="button is-light" @click="revert">
          Revert
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import PayLessCard from "@/components/instance/PayLessCard.vue";
import { ValidationObserver } from "vee-validate";
import saveRevert from "@/mixins/negotiatorMixins";
export default {
  mixins: [saveRevert],
  components: {
    PayLessCard,
    ValidationObserver
  },
  data() {
    return {
      ic: {
        negotiatorSOD1: null,
        negotiatorSOD2: null,
        offerAmountLabel: null,
        offerDateLabel: null
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      vm.$store.commit("SHOW_PROGRESS");
      vm.$store.commit("HIDE_NOTIFICATION");
      try {
        await vm.fetchPaymentPortalGlobalConfig();
        await vm.fetchPORSODStrategies();
        await vm.fetchPORTNGStrategies();
        await vm.fetchPaymentPortalInstanceConfig(vm.$route.params.id);
        await vm.fetchInstances();
        vm.loadView.call(vm);
      } catch (e) {
        vm.$store.commit(
          "SHOW_NOTIFICATION",
          `Error loading InstanceNegotiator; ${e}`
        );
      } finally {
        vm.$store.commit("HIDE_PROGRESS");
      }
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.$store.commit("SHOW_PROGRESS");
    this.$store.commit("HIDE_NOTIFICATION");
    try {
      await this.fetchPaymentPortalInstanceConfig(to.params.id);
      this.loadView();
    } catch (e) {
      this.$store.commit(
        "SHOW_NOTIFICATION",
        `Error loading instance id=${to.params.id}; ${e}`
      );
    } finally {
      this.$store.commit("HIDE_PROGRESS");
      next();
    }
  },
  methods: {
    updatePayLessCard(payload) {
      this.ic.offerAmountLabel = payload.offerAmountLabel;
      this.ic.offerDateLabel = payload.offerDateLabel;
      this.ic.negotiatorSOD1 = payload.negotiatorSOD1;
      this.ic.negotiatorSOD2 = payload.negotiatorSOD2;
    },
    loadView() {
      this.ic.instanceId = this.$route.params.id;
      this.ic.negotiatorSOD1 = this.$store.state.instanceConfig.negotiatorSOD1;
      this.ic.negotiatorSOD2 = this.$store.state.instanceConfig.negotiatorSOD2;
      this.ic.offerAmountLabel = this.$store.state.instanceConfig.offerAmountLabel;
      this.ic.offerDateLabel = this.$store.state.instanceConfig.offerDateLabel;
    },
    async saveNext() {
      await this.save();
      this.$router.push({ name: "payLessAccepted" });
    }
  }
};
</script>
