<template>
  <div class="card field">
    <div class="card-header">
      <p class="card-header-title">Negotiator Pay Less Page</p>
    </div>
    <div class="card-content">
      <div class="columns">
        <div class="column">
          <div class="field">
            <SODSelect
              v-model="sod1"
              :strategies="porsodStrategies"
              controlLabel="SOD Called On Page Load*"
              nullValueLabel="Select Negotiator SOD 1"
            />
          </div>

          <validation-provider
            tag="div"
            class="field"
            v-slot="{ errors, failed }"
            :rules="negotiatorRuleObj"
          >
            <div class="control">
              <label class="label">Negotiator Offer Amount Label*</label>
              <input
                type="text"
                class="input"
                v-model="amountLabel"
                name="Offer Amount Label"
              />
            </div>
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>

          <validation-provider
            tag="div"
            class="field"
            v-slot="{ errors, failed }"
            :rules="negotiatorRuleObj"
          >
            <div class="control">
              <label class="label">Negotiator Offer Date Label*</label>
              <input
                type="text"
                class="input"
                v-model="dateLabel"
                name="Offer Date Label"
              />
            </div>
            <p v-if="failed" class="help is-danger">{{ errors[0] }}</p>
          </validation-provider>

          <div class="field">
            <SODSelect
              v-model="sod2"
              :strategies="portngStrategies"
              controlLabel="SOD Run On Submit*"
              nullValueLabel="Select Negotiator SOD 2"
            />
          </div>
        </div>
        <div class="column">
          <img
            src="@/assets/pay_less_page_guide.png"
            alt="Pay Less Page Guide"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ValidationProvider, extend } from "vee-validate";
import { required, max, min } from "vee-validate/dist/rules";
import SODSelect from "@/components/SODSelect.vue";

extend("required", {
  ...required,
  message: "{_field_} is required."
});

extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be fewer than {length} characters."
});

extend("min", {
  ...min,
  params: ["length"],
  message: "{_field_} must have at least {length} characters."
});

export default {
  components: { ValidationProvider, SODSelect },
  props: [
    "offerAmountLabel",
    "offerDateLabel",
    "porsodStrategies",
    "portngStrategies",
    "negotiatorSOD1",
    "negotiatorSOD2"
  ],
  data() {
    return {
      amountLabel: this.offerAmountLabel,
      dateLabel: this.offerDateLabel,
      sod1: this.negotiatorSOD1,
      sod2: this.negotiatorSOD2,
      negotiatorRuleObj: { required: true, max: { length: 255 } }
    };
  },
  methods: {
    emitUpdate() {
      this.$emit("pay-less-card-updated", {
        offerAmountLabel: this.amountLabel,
        offerDateLabel: this.offerDateLabel,
        negotiatorSOD1: this.sod1,
        negotiatorSOD2: this.sod2
      });
    }
  },
  watch: {
    offerAmountLabel(nv) {
      this.amountLabel = nv;
    },
    offerDateLabel(nv) {
      this.dateLabel = nv;
    },
    negotiatorSOD1(nv) {
      this.sod1 = nv;
    },
    negotiatorSOD2(nv) {
      this.sod2 = nv;
    },
    amountLabel() {
      this.emitUpdate();
    },
    dateLabel() {
      this.emitUpdate();
    },
    sod1() {
      this.emitUpdate();
    },
    sod2() {
      this.emitUpdate();
    }
  }
};
</script>
